var prodcat = prodcat || {};
var site = site || {};

(function ($) {
  // @todo finalize sorting and filtering
  Drupal.behaviors.productGridV2 = {
    attach: function (context) {
      var $grids = $('.js-product-grid', context);
      var hasQuickshop = $grids.hasClass('product-grid--quickshop');
      var $products = $('.js-product-grid-item', context);

      $products.each(function () {
        var $this = $(this);
        var $product = $this.hasClass('product-brief--sku-product') ? $this : $this.find('.product-brief--sku-product');

        if ($product) {
          var skuBaseId = $product.data('skuBaseId');

          $product.trigger('product.updateRoute', [skuBaseId]);
        }
      });

      var collectGridProductIds = function ($gridItems) {
        var gridItems = $.makeArray($gridItems);
        var allProductIds = $.map(gridItems, function (cell) {
          return $(cell).data('productId');
        });

        return allProductIds;
      };
      var sortGrid = function (sortFn, $grid) {
        var $allProductCells = $('.js-product-grid-item', $grid);
        var allProductIds = collectGridProductIds($allProductCells);
        // Add the touts
        var $toutCells = $('.js-product-grid__item--tout', $grid);
        var allProductData = [];

        if (!$grid.data('productData')) {
          _.each(allProductIds, function (prodId) {
            allProductData.push(prodcat.data.getProduct(prodId));
          });
          $grid.data('productData', allProductData);
        }

        var sortedProductData = _.sortBy($grid.data('productData'), sortFn);
        var sortedCells = [];

        _.each(sortedProductData, function (prod) {
          if (prod) {
            sortedCells.push($('.js-product-grid-item[data-product-id=' + prod.PRODUCT_ID + ']'));
          }
        });
        $grid.empty();
        _.each(sortedCells, function (cell) {
          $grid.append($(cell));
        });
        $grid.append($toutCells);
        if (hasQuickshop && prodcat.ui.quickshop) {
          prodcat.ui.quickshop.init($grid);
        }
      };

      $(document).on('selectBox-selected', function (e, sortFn) {
        var $this = $(this);
        var $parent = ('.js-mpp-container', $this);
        var $grid = $('.js-product-grid', $parent);

        sortGrid(sortFn, $grid);
        site.util.grids.equalHeightsGrids($grid);
      });

      var filterData = function (filterBy, val, allProductIds) {
        var filteredProductIds = [];

        filteredProductIds = _.filter(allProductIds, function (prodId) {
          var prodData = prodcat.data.getProduct(prodId);
          var vals = prodData[filterBy];

          if (!vals) {
            return null;
          }
          vals = vals.split(',');

          return _.includes(vals, val);
        });

        return filteredProductIds;
      };
      var filterGrid = function (filterBy, val) {
        var $this = $(this);
        var $allProductCells = $('.js-product-grid-item', context);

        $allProductCells.show();
        if (val.length < 1) {
          return null;
        }
        var allProductIds = $('.js-product-grid-item').map(function () {
          return $this.data('product-id');
        });
        var filteredProductIds = filterData(filterBy, val, allProductIds);

        if (filteredProductIds.length > 0) {
          $allProductCells
            .show()
            .not(function () {
              var $gridItem = $this;
              var thisProductId = $gridItem.data('product-id');

              return _.includes(filteredProductIds, thisProductId);
            })
            .hide();
        }
      };

      $(document).on('mpp_filter:selected', function (e, filterBy, val) {
        filterGrid(filterBy, val);
      });
    }
  };
})(jQuery);
